strong {
  color: var(--accent-text-col);
}

i {
  color: gray;
}

#pageTitle {
  margin: 0;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0.2;
  font-size: 48px;
  text-transform: capitalize;
}

@media only screen and (max-width: 750px) {
  #pageTitle {
    opacity: 0;
  }
}

#Footer {
  width: 100%;
  text-align: center;
  margin-top: 15rem;
}

nav ul {
  margin: 0;
  padding: 1rem;
  list-style: none;
  background-color: var(--background-col);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

nav ul:nth-child(1) {
  justify-content: right;
}

nav ul > li,
.contact {
  border-radius: 100px;
  border: 2px solid var(--gray);
  transition: all .2s ease;
  padding-top: 20px;
  padding-bottom: 20px;
}

nav ul > li:hover {
  transform: translateY(-3px);
}

nav ul a,
.contact {
  text-align: center;
  padding: 20px 40px;
  text-decoration: none;
  color: var(--primary-text-col);
  cursor: pointer;
}

.contact {
  border-color: var(--accent-text-col) !important;
}

nav ul li {
  display: inline;
}

button {
  background-color: var(--gray);
  color: var(--primary-text-col);
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 650px) {
  nav ul:nth-child(1) {
    justify-content: center;
  }
}