@import url("https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/* Kode Mono */

* {
  box-sizing: border-box;
}

html {
  --background-col: #0f0f0f;
  --primary-text-col: cornsilk;
  --accent-text-col: #e0894f;
  --gray: #4e4e4e;
  overflow-x: clip;
}

body {
  margin: 0;
  background-color: var(--background-col);
  color: cornsilk;
  font-family: "Kode Mono", monospace;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}
