#Enquiry form {
    width: 100%;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    box-sizing: border-box;
}

#Enquiry form > * {
    width: min(500px, 95%);
    min-height: 50px;
    font-size: 18px;
    border: 0;
    font-family: "Kode Mono", monospace;
}

#Enquiry textarea {
    max-height: 300px;
}


#Enquiry button[type="submit"] {
    margin-top: 2rem;
    background-color: var(--accent-text-col);
    color: var(--primary-text-col);
    cursor: pointer;
}

#Enquiry button[type="submit"]:hover {
    opacity: 0.8;
}