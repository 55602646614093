#Home svg {
  font-size: 64px;
}

#Home h2 {
  text-align: center;
  font-size: 32px;
}

#Home p {
  font-size: 18px;
}

.Banner {
  --name-font-size: 96px;
  width: 80%;
  margin: 5rem auto;
  margin-bottom: 25rem;
}

.Banner h1 {
  margin: 0;
  font-size: var(--name-font-size);
}

#surname {
  margin-left: 5rem;
  position: relative;
  display: inline;
}

#surname h1 {
  position: relative;
}

#surname span {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  text-align: right;
  font-size: 32px;
}

#surname::after {
  content: "";
  display: block;
  animation: cursorBlink 1.5s steps(2) infinite;
  position: absolute;
  right: -3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: var(--name-font-size);
  background-color: var(--primary-text-col);
}

@media only screen and (max-width: 600px) {
  #Home h1 {
    font-size: 64px;
  }

  #surname {
    margin-left: 0;
  }

  #surname span {
    width: 300px;
    text-align: left;
  }
}

@keyframes cursorBlink {
  0% {
    visibility: hidden;
  }
}

.pointDown {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  position: absolute;
  bottom: 10vh;
  font-size: 24px;
  animation: bounce 0.8s infinite alternate;
}

.pointDown.left {
  justify-content: start;
  left: 5rem;
}

.pointDown.right {
  justify-content: end;
  right: 5rem;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

.sections {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  gap: 1rem;
  margin: 70vh 0 3rem;
}

.sections > div {
  justify-self: center;
  align-self: center;
  width: 80%;
  margin: 7rem auto;
}

@media only screen and (max-width: 750px) {
  .sections > div {
    width: 95%;
  }
}

.Skills {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.Skills > div {
  gap: 2rem;
}

.Skills span {
  transition: transform 0.7s ease;
}

.Skills span:hover {
  transform: scale(1.3);
}

.Skills .skillName {
  margin: 2rem 0;
  font-size: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Me {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
}

.Me > div > * {
  width: 50%;
}

@media only screen and (max-width: 750px) {
  .Me > div {
    flex-direction: column;
  }

  .Me > div > * {
    width: 80%;
  }
}

.Me img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 1000px;
  margin: 0 2rem;
  transition: all 0.7s ease;
}

.Me img:hover {
  transform: scale(1.2) rotate(3deg);
}

.WebDevelopment > div,
.ServerManagement > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  text-align: center;
  min-height: 300px;
  gap: 4rem;
  line-height: 1.5;
}

.WebDevelopment img,
.ServerManagement img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.WebDevelopment img {
  max-height: 650px;
  border-radius: 1rem;
}
